import React from "react";
import { IconClose } from "@dcc-cli/ic-shared-components";
import { convertFileSizeToMb } from "./sizeFormats";
import { InvalidFeedback } from "../ValidationMessage/InvalidFeedback";
import { DropZone } from "./DropZone";
import { IconFilePresent } from "../Icon/Icon";
import { truncate } from "lodash";

export interface Props {
  onFileSelectSuccess: (file: File) => void;
  isValid?: boolean;
  onFileRemove: () => void;
  fileAlreadyExist?: string;
  placeholder?: string;
}

export const FileUploader: React.FC<Props> = (props: Props) => {
  const { onFileSelectSuccess, isValid, onFileRemove, fileAlreadyExist, placeholder } = props;
  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleOnFileAdded = (value: File) => {
    setSelectedFile(value);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    onFileRemove();
  };

  React.useEffect(() => {
    if (fileAlreadyExist != null) {
      setSelectedFile({ name: fileAlreadyExist });
    }
  }, []);

  return (
    <>
      {isValid && selectedFile ? (
        <div>
          <div className="bg-lvl2 py-1 d-flex align-items-center">
            <IconFilePresent className="text-info m-2" size="lg" />
            <div className="flex-grow-1 text-info ">
              <div>
                <span className="font-weight-medium w-50 d-inline-block text-break">
                  {truncate(selectedFile.name, {
                    length: 15,
                  })}
                </span>
                <br />
                {selectedFile.size && <span className="text-right">{convertFileSizeToMb(selectedFile.size)} MB</span>}
              </div>
            </div>
            <div>
              <button className="btn btn-lg btn-flat-light">
                <IconClose onClick={handleRemoveFile} />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <DropZone onFileAdded={handleOnFileAdded} onFileSelectSuccess={onFileSelectSuccess} placeholder={placeholder} />
      )}

      {!isValid && <InvalidFeedback errorMessage="Please select a file to upload" errorType={"danger"} />}
    </>
  );
};
