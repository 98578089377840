import { toast, Id } from "react-toastify";

const genericErrorMessage = "An error occured. Contact the support if the problem persists";

/**
 * This method triggers a toaster on the UI when an error occured
 * https://github.com/fkhadra/react-toastify
 * @param message is an error message
 * @throws the current error
 */

export const toastifyError = (errorMessage?, message = genericErrorMessage): Id => {
  const error = errorMessage ?? errorMessage?.message ?? message;
  return toast.error(`Error while calling API : ${error}`, {
    autoClose: false,
  });
};

/**
 * This method triggers a toaster on the UI when an operation is in success
 * @param message is a success message
 */
export const toastifySuccess = (message: string) => {
  return toast.success(message);
};
