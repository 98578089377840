import { FC, useState } from "react";
import React = require("react");
import { CorrectionsStatusTable } from "./CorrectionsStatusTable";
import CorrectionEditor from "../CorrectionEditor/CorrectionEditor";
import { CorrectionsFilter } from "../CorrectionsList/CorrectionsFilter";
import { Loading } from "../../../components/common/Loading/Loading";
import { Button } from "../../../components/common/Buttons/Button";
import { mapToCorrectionDTO } from "../../../api/corrections.api.mapper";
import { Correction } from "../../../components/entities.typings";
import { CorrectionApi } from "../../../api/corrections.api";
import referentialApi from "../../../api/referentials.api";
import { correctionFeatures } from "utils/logger/features/correction.features";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { FilterChangedEvent } from "ag-grid-community";
import { useAuthenticationContext } from "hooks/contexts/useAuthenticationContext";
interface CorrectionState {
  corrections: Correction[];
  isFetching: boolean;
}

const initialState: CorrectionState = {
  corrections: [],
  isFetching: true,
};

export const CorrectionsStatusList: FC = () => {
  const [correctionsState, setCorrectionsState] = useState(initialState);
  const [gridApi, setGridApi] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [exportDisabled, setExportDisabled] = React.useState<boolean>(false);
  const [isSelfCreatedChecked, setIsSelfCreatedChecked] = React.useState(true);
  const [connectedUser, setConnectedUser] = React.useState<string>(null);

  const { trackEvent } = useMatomo();
  const { authState: authentication } = useAuthenticationContext();

  React.useEffect(() => {
    fetchCorrections();
    setConnectedUser(authentication.connectedUser);
  }, [authentication.connectedUser]);

  const fetchCorrections = () => {
    CorrectionApi.getCurrentCorrections().then(correctionsList => {
      setCorrectionsState({
        corrections: mapToCorrectionDTO(correctionsList.corrections),
        isFetching: false,
      });
      updateDisplay(correctionsList.corrections.length > 0);
    });
  };

  const onGridReady = params => {
    setGridApi(params.api);
    referentialApi.getCategories().then(categories => (params.api.categories = categories.values));
    params.api.sizeColumnsToFit();

    window.addEventListener("resize", function() {
      setTimeout(function() {
        params.api.sizeColumnsToFit();
      });
    });
  };

  const onFilterTextBoxChanged = event => {
    const value = event?.target.value;
    if (value) {
      gridApi?.setGridOption(
        "quickFilterText",
        event.target.value,
      );
      updateDisplay(gridApi?.rowModel.rowsToDisplay.length > 0);
    }
  };

  const onGridFilterChanged = (event: FilterChangedEvent) => {
    updateDisplay(event.api.getDisplayedRowCount() > 0);
  };

  const updateDisplay = (hasRows: boolean) => {
    if (!hasRows) {
      setExportDisabled(true);
      if (gridApi) {
        gridApi.showNoRowsOverlay();
      }
    } else {
      setExportDisabled(false);
      if (gridApi) {
        gridApi.hideOverlay();
      }
    }
  };

  const onShowModalClick = () => {
    trackEvent(correctionFeatures.correctionListCreateCorrection);
    setShowModal(true);
  };
  const onCloseModalClick = () => {
    setShowModal(false);
  };

  const onExportClick = () => {
    const filteredRows = gridApi?.rowModel.rowsToDisplay;
    if (filteredRows && filteredRows?.length !== correctionsState.corrections?.length) {
      CorrectionApi.exportCorrections(filteredRows.map(rowNode => rowNode.data.correctionId));
    } else {
      CorrectionApi.exportCorrections();
    }
  };

  const onClickCheckbox = () => {
    setIsSelfCreatedChecked(!isSelfCreatedChecked);
  };

  const corrections: Correction[] = isSelfCreatedChecked
    ? correctionsState.corrections.filter(x => x.createdBy === connectedUser)
    : correctionsState.corrections;

  return (
    <>
      <Loading isVisible={correctionsState.isFetching} />
      <CorrectionEditor
        onCorrectionCreation={fetchCorrections}
        showModalWindow={showModal}
        onCloseModalClick={onCloseModalClick}
      />
      <div>
        <div className="row">
          <div className="col-12 col-md-4 col-xxl-4 mt-2">
            <CorrectionsFilter onFilterTextBoxChanged={onFilterTextBoxChanged} />
          </div>
          <div className="col-7 col-md-5 col-xxl-4 mt-2">
            <Button
              text="Export"
              disabled={exportDisabled}
              className="btn btn-md btn-secondary"
              onClick={onExportClick}
            />
            <input
              type="checkbox"
              className="ml-3"
              checked={isSelfCreatedChecked}
              onChange={onClickCheckbox}
              placeholder="Only created by me"
              data-testid="createdByMeCheckbox"
            />
            <label className="ml-2 mb-0">Only created by me</label>
          </div>

          <div className="col-5 col-md-3 col-xxl-4 text-right mt-2">
            <Button text="New Correction" className="btn btn-lg btn-success" onClick={onShowModalClick} />
          </div>
        </div>
        <CorrectionsStatusTable
          onGridReady={onGridReady}
          onFilterTextBoxChanged={onGridFilterChanged}
          corrections={corrections}
          refresh={fetchCorrections}
        />
      </div>
    </>
  );
};
