import React from "react";
import { Spinner } from "../Spinner/Spinner";

export interface Props {
  isVisible?: boolean;
  message?: string;
}
export const Loading: React.FunctionComponent<Props> = ({ isVisible = true, message = "Loading..." }: Props) =>
  isVisible ? (
    <div className="row">
      <div className="col-md-12 col-12 no-padding featureLoader">
        <span className="loadingContainer">
          <Spinner />
          {message}
        </span>
      </div>
    </div>
  ) : null;
