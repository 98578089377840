import { FC } from "react";
import React = require("react");
import { AgGridReact } from "ag-grid-react";
import { Correction } from "../../../components/entities.typings";
import { ColDef, FilterChangedEvent, GridReadyEvent } from "ag-grid-community";
import { CorrectionStatus } from "../../../enums/CorrectionEnums";
import { CorrectionEditAction } from "./CorrectionEditAction";
import { CorrectionDetails } from "./CorrectionDetails";
import { decimalFormatter, formatDate, formatDateToPeriodDisplay } from "../../../common/helpers";
import CorrectionEditor from "../CorrectionEditor/CorrectionEditor";
import { CorrectionApi } from "../../../api/corrections.api";
import { agGridLicense } from "utils/ag-grid-license";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(agGridLicense);

export interface Props {
  corrections: Correction[];
  userProfile?: string;
  onGridReady(event: GridReadyEvent): void;
  onFilterTextBoxChanged(event: FilterChangedEvent): void;
  refresh(): void;
}

export const CorrectionsStatusTable: FC<Props> = (props: Props) => {
  const { corrections, onGridReady, onFilterTextBoxChanged, refresh } = props;

  const [showModal, setShowModal] = React.useState(false);
  const [correction, setCorrection] = React.useState(null);

  const onEditRowClick = (correctionId: number) => {
    CorrectionApi.getCorrection(correctionId).then(result => {
      setCorrection(result);
      setShowModal(true);
    });
  };

  const onCloseModalClick = () => {
    setShowModal(false);
  };

  const amountCellStyle = { textAlign: "right" };
  const gridMarginStyle = { marginBottom: "100px" };

  const formatValue = (value: string): string => (value !== "" ? decimalFormatter(parseFloat(value)) : "-");
  const checkValue = (value: string): string => (value ? value : "-");

  const statusBadgeComponent = (status) => (<span className={`badge badge-${getCorrectionStatusType(status.value)}`}>{status.value}</span>);

  const editActionComponent = (node: { data: Correction }) => (
    <CorrectionEditAction
      onclick={() => {
        onEditRowClick(node.data.correctionId);
      }}
      status={node.data.status}
    />
    )

  const detailCellRenderer = React.useCallback(CorrectionDetails, []);

  const columns: ColDef<Correction>[] = [
    { field: "correctionId", headerName: "ID", width:100, minWidth:40, sort:"desc", flex: 1, cellRenderer: "agGroupCellRenderer"},
    { field: "createdBy", headerName:"Created By", width:150, tooltipValueGetter: () => "createdBy", flex:1},
    { field: "dealCode", headerName:"Deal code", width:150, tooltipValueGetter: () => "dealCode", flex:1 },
    { field: "creationDate", headerName:"Creation date", valueFormatter: (date) => formatDate(date.value), width:135, flex:1},
    { field: "valueDate", headerName:"Value month", valueFormatter: (date) => formatDateToPeriodDisplay(date.value), width:130, flex:1},
    { field: "ssBl", headerName:"SSBL", width:140, minWidth:50, tooltipValueGetter: () => "ssBl", flex:1 },
    { field: "oldBpSharingText", headerName:"Old BP sharing", tooltipValueGetter: () => "oldBpSharingText", valueFormatter: (params) => checkValue(params.value), width:240, flex:1},
    { field: "newBpSharingText", headerName:"New BP sharing", tooltipValueGetter: () => "newBpSharingText", valueFormatter: (params) => checkValue(params.value), width:240, flex:1},
    { field: "oldAmountNbi", headerName:"Old NBI (€)", cellStyle:amountCellStyle, valueFormatter: (params) => formatValue(params.value), width:135, flex:1},
    { field: "amountNbi", headerName:"New NBI (€)", cellStyle:amountCellStyle, valueFormatter: (params) => formatValue(params.value), width:135, flex:1},
    { field: "oldAmountRwa", headerName:"Old RWA (€)", cellStyle:amountCellStyle, valueFormatter: (params) => formatValue(params.value), width:135, flex:1},
    { field: "amountRwa", headerName:"New RWA (€)", cellStyle:amountCellStyle, valueFormatter: (params) => formatValue(params.value), width:135, flex:1},
    { field: "status", headerName: "Status", width: 100, resizable:false, flex:1, cellRenderer: statusBadgeComponent},
    { field: "correctionId", headerName:"Edit", width:80, minWidth:20, resizable:false, flex:1, cellRenderer: editActionComponent},
  ];

  return (
    <>
      <CorrectionEditor
        showModalWindow={showModal}
        onCloseModalClick={onCloseModalClick}
        correction={correction}
        onCorrectionCreation={refresh}
      />
      <div className="ag-theme-alpine" style={gridMarginStyle}>
        <AgGridReact
          masterDetail={true}
          defaultColDef={{
            sortable: true,
            resizable: true,
            filter: true,
            width: 60,
            minWidth: 50,
          }}
          domLayout="autoHeight"
          rowData={corrections}
          pagination={true}
          paginationPageSize={20}
          onGridReady={onGridReady}
          onFilterChanged={onFilterTextBoxChanged}
          detailCellRenderer={detailCellRenderer}
          detailRowAutoHeight={true}
          detailCellRendererParams={{
            refresh: () => refresh(),
          }}
          columnDefs={columns}
        >
        </AgGridReact>
      </div>
    </>
  );
};

const getCorrectionStatusType = (status: CorrectionStatus) => {
  switch (status) {
    case CorrectionStatus.CANCELLED:
      return "warning";
    case CorrectionStatus.VALIDATED:
      return "success";
    case CorrectionStatus.REJECTED:
      return "danger";
    case CorrectionStatus.PROPOSED:
      return "secondary";
    default:
      return "warning";
  }
};