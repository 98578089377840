import { FC } from "react";
import React = require("react");
import { AgGridReact } from "ag-grid-react";
import { Deal } from "components/entities.typings";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { decimalFormatter } from "../../../common/helpers";
import { EditableField } from "./EditableField";
import { startsWith } from "lodash";
import { GridFilter } from "./GridFilter";
import { Button } from "../../../components/common/Buttons/Button";
import createLogger from "utils/logger/logger.helper";

export interface Props {
  deals: Deal[];
  totalPendingNbi: number;
  totalOldNbi: number;
  totalNBI: number;
  onShowModalClick(): void;
  onGridReady(event: GridReadyEvent): void;
  onEditNbi(deal: Deal): void;
  onEditRwa(deal: Deal): void;
  onEditBp(deal: Deal): void;
}

export const DealsTable: FC<Props> = (props: Props) => {
  const gridRef = React.useRef<AgGridReact>(null);
  const [disableExport, setDisableExport] = React.useState(true);
  const { deals, onGridReady, onEditNbi, onEditRwa, onEditBp, totalPendingNbi, totalOldNbi, totalNBI } = props;
  const amountCellStyle = { textAlign: "right" };
  const gridStyle = { height: "600px", marginBottom: "100px" };
  const logger = createLogger();
  
  const renderTotalPendingNbi = () => {
    const amountPending = totalPendingNbi > 0 ? totalNBI - totalOldNbi + totalPendingNbi : totalNBI;
    return (
      <>
        <div className="ag-status-name-value" hidden={deals.length === 0 ? true : false}>
          <label className="font-weight-bold">{`Total NBI: ${decimalFormatter(totalNBI)} €`}</label>
        </div>
        <div className="ag-status-name-value" hidden={deals.length === 0 ? true : false}>
          <label className="font-weight-bold">{`Total NBI (pending validation): ${decimalFormatter(
            amountPending
          )} €`}</label>
        </div>
      </>
    );
  };

  const editableBusinessProviderComponent = (params) => {
    return startsWith(params.data.dealCode, "DPO") || startsWith(params.data.dealCode, "DBP") ? (
      params.value
    ) : (
      <EditableField
        onEdit={() => onEditBp(params.data)}
        value={params.value}
        valueFormatted={params.valueFormatted}
        className="px-0 mr-0"
      />
    );
  };

  const editableNbiComponent = (params) => {
    return (
      <EditableField
        onEdit={() => onEditNbi(params.data)}
        value={params.value}
        valueFormatted={params.valueFormatted}
        className="pl-1 pr-0 mr-0"
        direction="right"
      />
    );
  };

  const editableRwaComponent = (params) => {
    return (
      <EditableField
        onEdit={() => onEditRwa(params.data)}
        value={params.value}
        valueFormatted={params.valueFormatted}
        className="pl-1 pr-0 mr-0"
        direction="right"
      />
    );
  }

  const onGridFilterChanged = event => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      event.target.value,
    );

    const emptyRows = gridRef.current.api.getRenderedNodes().length === 0;

    setDisableExport(emptyRows);

    if (emptyRows) {
      gridRef.current.api.showNoRowsOverlay()
    } else {
      gridRef.current.api.hideOverlay()
    }
  };

  const onExportClick = () => {
    logger.functional("Deal search screen", "Export deals button clicked", "CPR-CORRECTION-UI");
    gridRef.current.api.exportDataAsExcel();
  };

  React.useEffect(() => {
    setDisableExport(deals?.length === 0 || (gridRef.current.api?.getRenderedNodes().length ?? 0) === 0);
  }, [deals]);


  const columns: ColDef<Deal>[] =  [
    { field: "dealCode", headerName:"Deal code", width: 120, minWidth: 50},
    { field: "valueMonth", headerName:"Value month", width: 130 },
    { field: "ssBl", headerName:"SSBL", width: 85, minWidth: 50, tooltipValueGetter: () => "ssBl" },
    { field: "pc", headerName:"Profit center", width: 130, minWidth: 50 },
    { field: "providerBusiness", headerName:"Business provider", width: 160, tooltipValueGetter: () => "providerBusiness", cellRenderer:editableBusinessProviderComponent },
    { field: "po", headerName:"Primary obligor", width: 150, minWidth: 50, tooltipValueGetter: () => "po", flex: 1 },
    { field: "be", headerName:"Booking entity", width: 145, minWidth: 50, tooltipValueGetter: () => "be", flex: 1 },
    { field: "oe", headerName:"Originating entity", width: 165, minWidth: 50, tooltipValueGetter: () => "oe", flex: 1 },
    { field: "product", headerName:"Product", width: 100, minWidth: 50, tooltipValueGetter: () => "product", flex: 1 },
    { field: "agency", headerName:"Agency", width: 100, minWidth: 50, flex: 1 },
    { field: "sourceApp", headerName:"Deal source", width: 125, minWidth: 50, flex: 1 },
    { field: "supplier", headerName:"Deal supplier", width: 135, minWidth: 50, flex: 1 },
    { field: "indicator", headerName:"Indicator", width: 110, minWidth: 50, flex: 1 },
    { field: "isIb", headerName:"Is IB", width: 85, minWidth: 50, flex: 1 },
    { field: "nbi", headerName:"NBI (€)", width: 100, minWidth: 80, flex: 1, cellStyle:amountCellStyle, valueFormatter: (params) => decimalFormatter(params.value), cellRenderer:editableNbiComponent },
    { field: "rwa", headerName:"RWA (€)", width: 100, minWidth: 80, flex: 1, cellStyle:amountCellStyle, valueFormatter: (params) => decimalFormatter(params.value), cellRenderer:editableRwaComponent }
  ]

  return (
    <>
    <div className="row mb-1">
      <div className="col-5 col-md-4 col-xxl-2">
        <GridFilter onGridFilterChanged={onGridFilterChanged} />
      </div>
      <div className="col-2">
        <Button
          text="Export"
          className="btn btn-md btn-secondary"
          disabled={disableExport}
          onClick={onExportClick}
        />
      </div>
      <div className="col-5 col-md-6 col-xxl-8 text-right">
        <Button text="New Correction" className="btn btn-md btn-success" onClick={props.onShowModalClick} />
      </div>
    </div>
      <div className="ag-theme-alpine" style={gridStyle}>
        <AgGridReact
          ref={gridRef}
          key={totalNBI}
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
          rowData={deals}
          columnDefs={columns}
          pagination={true}
          paginationPageSize={20}
          onGridReady={onGridReady}
          statusBar={{
            statusPanels: [
              {
                statusPanel: renderTotalPendingNbi,
                align: "left",
              },
            ],
          }
        }
        >
        </AgGridReact>
      </div>
    </>
  );
};
