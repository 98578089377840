import React from "react";
import { noop } from "lodash";
import CorrectionModal from "../CorrectionModal/CorrectionModal";
import { Correction } from "../../../components/entities.typings";
import { DealSearchFiltersState } from "components/Deal/DealsSearch/DealSearchFilters";
import { DEAL_SEARCH_MODE } from "enums/DealEnum";

interface CorrectionEditorProps {
  showModalWindow?: boolean;
  onCorrectionCreation?: () => void;
  onCloseModalClick?: (showModal: boolean) => void;
  correction?: Correction;
  dealSearchFiltersState?: DealSearchFiltersState;
  searchMode?: DEAL_SEARCH_MODE;
  fetchDeals?: (
    dealcode: string,
    startDate: string,
    endDate: string,
    searchMode: string,
    ssbl?: string,
    originatingCountry?: string,
    po?: string
  ) => void;
}

const CorrectionEditor: React.FC<CorrectionEditorProps> = ({
  onCorrectionCreation = noop,
  onCloseModalClick,
  showModalWindow,
  correction,
  fetchDeals,
  dealSearchFiltersState,
  searchMode,
}: CorrectionEditorProps) => {
  const closeNewCorrectionModal = (): void => {
    onCloseModalClick(false);
  };

  const onCorrectionSubmit = (): void => {
    onCorrectionCreation();
  };

  return (
    <div className="btn-toolbar justify-content-end pb-1 mr-5">
      <CorrectionModal
        show={showModalWindow}
        onClose={closeNewCorrectionModal}
        onSubmit={onCorrectionSubmit}
        correction={correction}
        dealSearchFiltersState={dealSearchFiltersState}
        fetchDeals={fetchDeals}
        searchMode={searchMode}
      />
    </div>
  );
};

export default CorrectionEditor;
