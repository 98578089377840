import React from "react";
import { noop } from "lodash";

export interface CheckboxProps {
  disabled?: boolean;
  checked: boolean;
  name: string;
  label: string;
  containerClassName?: string;
  onClick: (value: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ disabled, checked, name, label, containerClassName, onClick }: CheckboxProps) => {
  const handleOnClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    onClick(e.target.checked);
  };
  return (
    <div className={`custom-control custom-checkbox ${containerClassName}`}>
      <>
        <input
          id={`customCheckbox${name}`}
          name={`checkbox_${name}`}
          className="custom-control-input"
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={!disabled ? handleOnClick : noop}
        />
        <label htmlFor={`customCheckbox${name}`} className={`custom-control-label ${disabled ? "disabled" : ""}`}>{label}</label>
      </>
    </div>
  );
};

export default Checkbox;
