import { Loading } from "components/common/Loading/Loading";
import React from "react";
import { IconClose } from "@dcc-cli/ic-shared-components";
import { If } from "components/common/If/If";
import { ComponentSize } from "components/common/components.typings";
import { Button } from "@sgbs-ui/core";

export interface Props {
  show?: boolean;
  withHeader?: boolean;
  title: string;
  size?: ComponentSize;
  subTitle?: string;
  withAction?: boolean;
  backdropClose?: boolean;
  cancelLabel?: string;
  submitLabel?: string;
  onClose: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  onPostMount?: () => void;
  isSubmitValid?: boolean;
  isLoading?: boolean;
  children: React.ReactNode;
}

export class Modal extends React.Component<Props> {
  private readonly modalContent: React.RefObject<HTMLDivElement>;
  public constructor(props: Props) {
    super(props);
    this.modalContent = React.createRef();
    this.onShouldClose = this.onShouldClose.bind(this);
  }

  public componentDidMount(): void {
    if (this.props.onPostMount) {
      this.props.onPostMount();
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.show !== this.props.show) {
      this.props.show ? document.body.setAttribute("class", "modal-open") : document.body.setAttribute("class", "");
    }
  }

  public componentWillUnmount(): void {
    document.body.setAttribute("class", "");
  }

  public render() {
    const {
      show = false,
      withHeader = true,
      withAction = true,
      size = "lg",
      title,
      subTitle,
      cancelLabel,
      onCancel,
      submitLabel,
      onSubmit,
      onClose,
      isSubmitValid = true,
      isLoading,
    } = this.props;
    return show ? (
      <>
        <div
          className="modal fade show d-block"
          tabIndex={-1}
          role="dialog"
          onClick={this.onShouldClose}
        >
          <div className={`modal-dialog modal-${size}`} role="document">
            <div className="modal-content shadow-max" ref={this.modalContent}>
              <If condition={withHeader} elseComponent={<span className="pt-5" />}>
                <div className="modal-header">
                  <span>
                    <h3 className="modal-title">{title}</h3>
                    {subTitle && <h5 className="modal-title">{subTitle}</h5>}
                  </span>
                  <button type="button" className="close icon" data-dismiss="modal" onClick={onClose}>
                    <IconClose />
                  </button>
                </div>
              </If>
              <div className="modal-body">{this.props.children}</div>
              <If condition={withAction}>
                <div className="modal-footer pt-0">
                  <Button text={cancelLabel} className="btn btn-outline-secondary" onClick={onCancel} />
                  <If condition={!!onSubmit}>
                    {isLoading ? (
                      <Loading isVisible={isLoading} />
                    ) : (
                      <Button
                        onClick={onSubmit}
                        className="btn btn-primary"
                        text={submitLabel}
                        disabled={!isSubmitValid}
                      />
                    )}
                  </If>
                </div>
              </If>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" />
      </>
    ) : null;
  }

  private onShouldClose(event: any) {
    if (this.modalContent && !this.modalContent.current.contains(event.target) && this.props.backdropClose) {
      this.props.onClose();
    }
  }
}
