import React, { useRef } from "react";
import { Button } from "@sgbs-ui/core";
import { SgDatePicker } from "@sg-bootstrap/components/dist/react-library/src/components";
import { SgDatePickerCustomEvent } from "@sg-bootstrap/components";
import { dateToString, stringToDate } from "utils/date/dateUtils";
import { DatePickerState } from "../../../components/Correction/CorrectionModal/correction-modal.typings";
import { Label } from "../../../components/common/ReadOnlyField/Label";
import { FormField } from "../../../components/common/ReadOnlyField/FormField";
import { isValidFormState } from "./DealSearchFiltersHelper";
import { isEmpty } from "lodash";
import { DealsMultipleCriteriaSearch } from "./DealsMultipleCriteriaSearch";
import { DEAL_SEARCH_MODE } from "../../../enums/DealEnum";
import AccountSinglePicker from "components/common/Pickers/AccountSinglePicker/AccountSinglePicker";
import { SearchInput } from "components/common/SearchInput/SearchInput";
import ReferentialPicker from "components/Correction/CorrectionModal/components/ReferentialPicker";
import {
  ORIGINATING_COUNTRY,
  SUB_SUB_BUSINESS_LINE,
} from "components/Correction/CorrectionModal/utils/correction-modal.const";
import { DropDownState } from "common/common.typings";
import { getDealSearchEndDate, getSearchModeId, isValid } from "../Helper/DealSearchFilters.helper";
import { deaLSearchModes } from "components/entities.typings";
import { getInitYearBasedOnCurrentDate } from "components/common/DatePicker/month-year-selector.helper";

interface CodeState {
  value?: string;
  isValid: boolean;
}

interface PoState {
  dropdownState: DropDownState;
  clientId: string;
}

const END_DATE_INVALID_MESSAGE= "End date should be greater than start date"

export const INIT_STATE: DealSearchFiltersState = {
  startDate: { isValid: true, date: `${getInitYearBasedOnCurrentDate(new Date())}-01-01` },
  endDate: { isValid: true, date: getDealSearchEndDate(new Date()) },
  code: { isValid: false, value: "" },
  ssbl: { isValid: false },
  originatingCountry: { isValid: true },
  po: { dropdownState: { isValid: false, selected: null }, clientId: undefined },
};

export interface DealSearchFiltersProps {
  dealSearchFiltersState: DealSearchFiltersState;
  setDealSearchFiltersState: (x: DealSearchFiltersState) => void;
  searchMode: DEAL_SEARCH_MODE;
  setSearchMode: (searchMode: DEAL_SEARCH_MODE) => void;
  onSearch: (
    code: string,
    startDate: string,
    endDate: string,
    searchMode: deaLSearchModes,
    ssbl?: string,
    originatingCountry?: string,
    po?: string
  ) => void;
}

export interface DealFilters {
  startDate: string;
  endDate: string;
  code?: string;
}

export interface DealSearchFiltersState {
  startDate: DatePickerState;
  endDate: DatePickerState;
  code: CodeState;
  ssbl: DropDownState;
  originatingCountry: DropDownState;
  po: PoState;
}

export const DealSearchFilters: React.FC<DealSearchFiltersProps> = (props: DealSearchFiltersProps) => {
  const { dealSearchFiltersState, searchMode, setSearchMode } = props;
  const [isFilterStateValid, setIsFilterStateValid] = React.useState<boolean>(false);
  const startDatePickerRef = useRef<HTMLSgDatePickerElement>(null);
  const endDatePickerRef = useRef<HTMLSgDatePickerElement>(null);

  React.useEffect(() => {
    setIsFilterStateValid(isValidFormState(dealSearchFiltersState, searchMode));
  }, [dealSearchFiltersState]);

  React.useEffect(() => {
    props.setDealSearchFiltersState(INIT_STATE);
  }, [searchMode]);

  React.useEffect(() => {
    props.setDealSearchFiltersState({
      ...dealSearchFiltersState,
      po: {
        ...dealSearchFiltersState.po,
        dropdownState: {
          selected: dealSearchFiltersState.po.dropdownState.selected,
          isValid: isValid(!!dealSearchFiltersState.ssbl.selected?.id, !isEmpty(dealSearchFiltersState.po.clientId)),
        },
      },
      ssbl: {
        ...dealSearchFiltersState.ssbl,
        isValid: isValid(!!dealSearchFiltersState.ssbl.selected?.id, !!dealSearchFiltersState.po.clientId),
      },
      originatingCountry: {
        ...dealSearchFiltersState.originatingCountry,
        isValid: true,
      },
    });
  }, [
    dealSearchFiltersState.ssbl.isValid,
    dealSearchFiltersState.po.dropdownState.isValid,
    dealSearchFiltersState.originatingCountry.isValid,
  ]);

  const handleSubmit = event => {
    event.preventDefault();
    if (searchMode === DEAL_SEARCH_MODE.dealCode || searchMode === DEAL_SEARCH_MODE.isinCode) {
      props.onSearch(
        dealSearchFiltersState.code.value,
        dealSearchFiltersState.startDate.date,
        dealSearchFiltersState.endDate.date,
        getSearchModeId(searchMode)
      );
    } else {
      if (
        dealSearchFiltersState.ssbl.selected?.label ||
        dealSearchFiltersState.po.dropdownState.selected?.label ||
        dealSearchFiltersState.originatingCountry.selected?.label
      ) {
        props.onSearch(
          dealSearchFiltersState.code.value,
          dealSearchFiltersState.startDate.date,
          dealSearchFiltersState.endDate.date,
          getSearchModeId(searchMode),
          dealSearchFiltersState.ssbl.selected?.label,
          dealSearchFiltersState.originatingCountry.selected?.label,
          dealSearchFiltersState.po.dropdownState.selected?.id
        );
      }
    }
  };

  const initDateValue = (event: SgDatePickerCustomEvent<void>, initialDate: string) => {
    event.target.setSelection([new Date(initialDate).valueOf()]);
  }

  const onStartDateSelected = (event: SgDatePickerCustomEvent<{
    date: Date;
    timestamp: number;
    formatted: string;
  }>) => {
    const startDate = event.detail.date;
    const startDateAsString = dateToString(event.detail.date);
    event.target.setValidationState(!isEmpty(startDateAsString) ? "valid": "invalid", "");
    if (startDate > stringToDate(dealSearchFiltersState.endDate.date)) {
      endDatePickerRef.current.setValidationState("invalid", END_DATE_INVALID_MESSAGE);
      props.setDealSearchFiltersState({
        ...dealSearchFiltersState,
        startDate: {
          date: startDateAsString,
          isValid: !isEmpty(startDateAsString),
        },
        endDate: {
          ...dealSearchFiltersState.endDate,
          isValid: false,
          errorMessage: END_DATE_INVALID_MESSAGE,
        },
      });
    } else if (!isEmpty(dealSearchFiltersState.endDate.date)) {
      endDatePickerRef.current?.setValidationState(!isEmpty(startDateAsString) ? "valid" : "invalid", "");
      props.setDealSearchFiltersState({
        ...dealSearchFiltersState,
        startDate: {
          date: startDateAsString,
          isValid: !isEmpty(startDateAsString),
        },
        endDate: {
          ...dealSearchFiltersState.endDate,
          isValid: true,
          errorMessage: null,
        },
      });
    }
  }

  
  const onEndDateSelected = (event: SgDatePickerCustomEvent<{
    date: Date;
    timestamp: number;
    formatted: string;
  }>) => {
    const endDate = event.detail.date;
    const endDateAsString = dateToString(event.detail.date);
    if (stringToDate(dealSearchFiltersState.startDate.date) > endDate) {
      event.target.setValidationState("invalid", END_DATE_INVALID_MESSAGE);
      props.setDealSearchFiltersState({
          ...dealSearchFiltersState,
          endDate: {
            date: endDateAsString,
            isValid: false,
            errorMessage: END_DATE_INVALID_MESSAGE,
          }
      });
    } else {
      event.target.setValidationState(!isEmpty(endDateAsString) ? "valid" : "invalid", "");
      props.setDealSearchFiltersState({
        ...dealSearchFiltersState,
        endDate: { date: endDateAsString, isValid: !isEmpty(endDateAsString) },
        }
      );
    }
  };

  const onDateUnselected = (event: SgDatePickerCustomEvent<{
    date: Date;
    timestamp: number;
    formatted: string;
  }>) => {
    event.target.setSelection([event.detail.timestamp]);
  }

  const resetForm = e => {
    e.preventDefault();
    startDatePickerRef.current.setSelection([new Date(INIT_STATE.startDate.date).valueOf()])
    endDatePickerRef.current.setSelection([new Date(INIT_STATE.endDate.date).valueOf()])
    props.setDealSearchFiltersState(INIT_STATE);
  };


  return (
    <>
      <form onSubmit={handleSubmit} id="filtersForm">
        <div className="row pl-2">
          <div className="col-md-10 row">
            <div className="mr-2">
              <FormField id="DealCode">
                <DealsMultipleCriteriaSearch
                  className={`form-control ${dealSearchFiltersState.code.isValid ? "" : "is-invalid"}`}
                  inputValue={dealSearchFiltersState.code.value}
                  handleSearchMode={e => setSearchMode(e)}
                />
              </FormField>
            </div>

            <div className="mr-2">
              <Label displayLabel={"From"} htmlFor={"From"} className="text-secondary mb-0" />
              <SgDatePicker
                id="startDatePicker"
                ref={startDatePickerRef}
                placeholder=""
                selectionMode="date"
                itemSelection="single"
                displaySelectedDates
                onReady={e => initDateValue(e, dealSearchFiltersState.startDate.date)}
                onDateSelected={onStartDateSelected}
                onDateUnSelected={onDateUnselected}
                showClearButton={false}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}>
                </SgDatePicker>
            </div>
            <div className="mr-2">
              <Label displayLabel={"To"} htmlFor={"To"} className="text-secondary mb-0" />
              <SgDatePicker
                id="endDatePicker"
                ref={endDatePickerRef}
                placeholder=""
                selectionMode="date"
                itemSelection="single"
                displaySelectedDates
                onReady={e => initDateValue(e, dealSearchFiltersState.endDate.date)}
                onDateSelected={onEndDateSelected}
                onDateUnSelected={onDateUnselected}
                showClearButton={false}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}>
                </SgDatePicker>
            </div>
            <div
              className={`col-xl-12 ${searchMode !== DEAL_SEARCH_MODE.otherCriteria ? "col-xxl-6 pl-0 mr-2" : " mr-0"}`}
            >
              <div className={`${searchMode !== DEAL_SEARCH_MODE.otherCriteria ? "" : "d-none"}`}>
                <Label displayLabel={searchMode} htmlFor={searchMode} className="text-secondary mb-0" />
                <SearchInput
                  iconName="search"
                  className={`form-control  ${dealSearchFiltersState.code.isValid ? "" : "is-invalid"}`}
                  placeHoldertext={`Search by ${searchMode}`}
                  value={dealSearchFiltersState.code.value}
                  onChange={e =>
                    props.setDealSearchFiltersState({
                      ...dealSearchFiltersState,
                      code: { value: e.target.value, isValid: !isEmpty(e.target.value) },
                    })
                  }
                />
              </div>
              <div className={`row ${searchMode !== DEAL_SEARCH_MODE.otherCriteria ? "d-none" : ""}`}>
                <div className="col-md-4 pl-0 pr-2">
                  <div>
                    <ReferentialPicker
                      label={SUB_SUB_BUSINESS_LINE}
                      disabled={false}
                      isValid={dealSearchFiltersState.ssbl?.isValid}
                      selected={dealSearchFiltersState.ssbl?.selected}
                      onSelect={selected => {
                        props.setDealSearchFiltersState({
                          ...dealSearchFiltersState,
                          ssbl: { selected, isValid: !!selected.id },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-2">
                  <div>
                    <Label displayLabel={"Client"} htmlFor={"Client"} className="text-secondary mb-0" />
                    <AccountSinglePicker
                      isInError={!dealSearchFiltersState.po?.dropdownState?.isValid}
                      errorMessage={"Please select a Client"}
                      selectedId={dealSearchFiltersState.po?.clientId ?? undefined}
                      onChange={e => {
                        props.setDealSearchFiltersState({
                          ...dealSearchFiltersState,
                          po: {
                            dropdownState: {
                              selected: { id: e.bdrCommercialId, label: e.name },
                              isValid: !!e.bdrCommercialId,
                            },
                            clientId: e.account?.id,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 pl-0 pr-0">
                  <div>
                    <ReferentialPicker
                      label={ORIGINATING_COUNTRY}
                      disabled={false}
                      isValid={dealSearchFiltersState.originatingCountry?.isValid}
                      selected={dealSearchFiltersState.originatingCountry?.selected}
                      onSelect={selected => {
                        props.setDealSearchFiltersState({
                          ...dealSearchFiltersState,
                          originatingCountry: { selected, isValid: !!selected.id },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col mt-3 text-right row">
            <div className="col">
              <Button text="Reset" className="btn-lg btn-outline-secondary mb-2 mr-0" onClick={resetForm} />
              <Button
                type="submit"
                className="btn-lg btn-primary ml-2 mb-2 mr-0"
                text="Search"
                disabled={!isFilterStateValid}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
