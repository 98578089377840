import React from "react";
interface LabelProps {
  displayLabel: string;
  htmlFor?: string;
  className?: string;
}

export const Label: React.FunctionComponent<LabelProps> = ({ displayLabel, htmlFor, className }: LabelProps) => (
  <label htmlFor={htmlFor} className={className}>
    {displayLabel}
  </label>
);
