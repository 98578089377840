import React from "react";
import { Loading } from "components/common/Loading/Loading";
import { sgwtConnect } from "common/auth/sgwtConnect";
import { useAuthenticationContext } from "hooks/contexts/useAuthenticationContext";

interface AuthenticationProps {
  children: React.ReactNode;
}

// eslint-disable-next-line react/prop-types
export const Authentication: React.FC<AuthenticationProps> = ({children}: AuthenticationProps ) => {
  const { authState: authentication } = useAuthenticationContext();

  // This is used by the account center :
  // https://sgithub.fr.world.socgen/DT-Widgets/sgwt-widgets/blob/master/src/sgwt-account-center/README.md#authentication
  window.sgwtConnect = sgwtConnect;

  if(authentication.loggedIn){
    return <>{children}</>
  }
  else{
    return <Loading />
  }
};