import React from "react";
import { CorrectionType, CorrectionTypeEnum } from "../../../../enums/CorrectionEnums";
import { FormField } from "../../../common/ReadOnlyField/FormField";
import { Referential } from "../../../entities.typings";
import CorrectionTypeSwitcher from "./CorrectionTypeSwitcher";
import ReferentialPicker from "./ReferentialPicker";
import {
  AGENCY_NAME,
  AMOUNT,
  NEW_AMOUNT,
  NEW_AMOUNT_IF_VALIDATED,
  BOOKING_ENTITY,
  DEAL_CODE,
  INDICATOR,
  IS_IB,
  ORIGINATING_ENTITY,
  PO,
  PRODUCT_LABEL,
  PROFIT_CENTER,
  SUB_SUB_BUSINESS_LINE,
  VALUE_MONTH,
} from "../utils/correction-modal.const";
import { DealInformationSectionState, ReferentialProps } from "../correction-modal.typings";
import BpClientsRenderer from "./BpClientsRenderer";
import { InvalidFeedback } from "components/common/ValidationMessage/InvalidFeedback";
import { amountInputErrorMessages } from "../utils/correction-modal.helper";
import { find, head, startsWith } from "lodash";
import Checkbox from "components/common/Checkbox/Checkbox";
import { MonthYearSelector } from "components/common/DatePicker/MonthYearSelector";
import { MONTH_REFERENTIALS, QUARTER_REFERENTIALS } from "components/common/DatePicker/month-year-selector.const";
import {
  getInitYearBasedOnCurrentDate,
  getMonthYearFromDateString,
} from "components/common/DatePicker/month-year-selector.helper";

export interface DealInformationsSectionProps {
  dealCode: string;
  indicators: Referential[];
  selectedDealInformation: DealInformationSectionState;
  editMode: boolean;
  isDealCorrection: boolean;
  onCorrectionTypeSelect: (item: CorrectionType) => void;
  onAmountSelect: (item: string) => void;
  onValueDateSelected: (item: string) => void;
  onSelect: (value: Referential, propName: ReferentialProps, index?: number) => void;
  onAddBp: () => void;
  removeBpClient: (index: number) => void;
  onSharingSelect: (item: string, index: number) => void;
  onIsIbSelect: (item: boolean) => void;
}

const DealInformationsSection: React.FC<DealInformationsSectionProps> = ({
  dealCode,
  indicators = [],
  selectedDealInformation: {
    BookingEntity: selectedBookingEntity,
    OriginatingEntity: selectedOriginatingEntity,
    Ssbl: selectedSsbl,
    BpSharing: selectedBpSharing,
    PoClient: selectedPoClients,
    ProfitCenter: selectedProfitCenter,
    AgencyName: selectedAgencyNames,
    ProductLabel: selectedProductLabels,
    Indicator: selectedIndicator,
    CorrectionType: correctionType,
    Amount: amount,
    ValueDate: valueDate,
    IsIB: isIB,
  },
  editMode,
  isDealCorrection,
  onCorrectionTypeSelect,
  onAmountSelect,
  onValueDateSelected,
  onSelect,
  onAddBp,
  removeBpClient,
  onSharingSelect,
  onIsIbSelect,
}: DealInformationsSectionProps) => {
  const [isPcDropDownDisabled, setIsPcDropDownDisabled] = React.useState<boolean>(!selectedProfitCenter.isValid);
  const [profitCenterSearchTerms, setProfitCenterSearchTerms] = React.useState<string | null>(null);
  const [isProfitCenterTouched, setIsProfitCenterTouched] = React.useState<boolean>(false);
  const [isSsblTouched, setIsSsblTouched] = React.useState<boolean>(false);
  const [defaultProfitCenter, setDefaultProfitCenter] = React.useState<Referential>(null);

  const [month, year] = getMonthYearFromDateString(valueDate?.date);
  const [valueMonth, setValueMonth] = React.useState<string>(() => {
    if (correctionType.correctionType === CorrectionTypeEnum.RWA) {
      return QUARTER_REFERENTIALS.find(item => item.id === month)?.id ?? "";
    } else {
      return MONTH_REFERENTIALS.find(item => item.id === month)?.id ?? "";
    }
  });
  const [valueYear, setValueYear] = React.useState<string>(
    year ? year : getInitYearBasedOnCurrentDate(new Date()).toString()
  );

  React.useEffect(() => {
    if (valueMonth && valueYear) {
      onValueDateSelected(`${valueMonth} ${valueYear}`);
    } else {
      onValueDateSelected("");
    }
  }, [valueMonth, valueYear]);

  React.useEffect(() => {
    if (isPcDropDownDisabled) {
      onSelect({ id: null, label: "" }, "ProfitCenter");
    }
  }, [isPcDropDownDisabled]);

  React.useEffect(() => {
    if (selectedSsbl?.selected?.label) {
      setProfitCenterSearchTerms(`fpv_ssbl:${selectedSsbl?.selected?.label}`);
    }
  }, [selectedSsbl]);

  const handleOnBookingEntitySelect = (item: Referential): void => {
    onSelect(item, "BookingEntity");
    if (item.id && !selectedOriginatingEntity.isValid) {
      onSelect(item, "OriginatingEntity");
    }
  };

  const handleOnOriginatingEntitySelect = (item: Referential): void => {
    onSelect(item, "OriginatingEntity");
    if (item.id && !selectedBookingEntity.isValid) {
      onSelect(item, "BookingEntity");
    }
  };

  const handleSsblSelect = (item: Referential): void => {
    if (item.id) {
      setIsPcDropDownDisabled(false);
    } else {
      setIsPcDropDownDisabled(true);
    }
    onSelect(item, "Ssbl");
    setIsProfitCenterTouched(false);
  };

  const handleProfitCenterSelect = (item: Referential): void => {
    onSelect(item, "ProfitCenter");
    setIsProfitCenterTouched(true);
  };

  const handleCorrectionTypeToggle = (selectedCorrectionType: CorrectionType): void => {
    if (selectedCorrectionType === CorrectionTypeEnum.RWA) {
      onSelect({ id: null, label: "" }, "Indicator");
      if (!QUARTER_REFERENTIALS.find(q => q.id === valueMonth)) {
        setValueMonth("");
      }
    }
    onCorrectionTypeSelect(selectedCorrectionType);
  };

  const handleValueMonthChange = (month: string): void => {
    setValueMonth(month);
  };

  const handleValueYearChange = (year: string): void => {
    setValueYear(year);
  };

  const isBpCorrection = correctionType.correctionType === CorrectionTypeEnum.BP;

  const defaultSelectedProfitCenter = React.useCallback((data: Referential[]) => {
    setDefaultProfitCenter(find(data, x => startsWith(x.label, "TECH")) ?? head(data));
  }, []);

  React.useEffect(() => {
    if (defaultProfitCenter && !isProfitCenterTouched && !selectedProfitCenter?.selected?.id) {
      onSelect(defaultProfitCenter, "ProfitCenter");
    }
    if (defaultProfitCenter && isSsblTouched) {
      onSelect(defaultProfitCenter, "ProfitCenter");
    }
  }, [defaultProfitCenter]);

  return (
    <>
      <h5>Deal Information</h5>
      {!isBpCorrection && (
        <div className="row">
          <div className="col-md-4 form-group">
            <CorrectionTypeSwitcher
              selectedValue={correctionType.correctionType}
              disabled={editMode || isDealCorrection}
              onClick={handleCorrectionTypeToggle}
            />
          </div>
          <div className="col-md-2 form-group text-right">
            <Checkbox
              checked={isIB.value}
              name="isIb"
              onClick={onIsIbSelect}
              label={IS_IB}
              disabled={isDealCorrection}
              containerClassName="mr-2 mt-2 pt-3"
            />
          </div>
          <div className={`col-md-6 form-group ${isBpCorrection ? "d-none" : ""}`}>
            <MonthYearSelector
              useQuarter={correctionType.correctionType === CorrectionTypeEnum.RWA}
              label={VALUE_MONTH}
              value={`${valueMonth} ${valueYear}`}
              disabled={isDealCorrection}
              onMonthChange={handleValueMonthChange}
              onYearChange={handleValueYearChange}
            />
          </div>
          <div className="col-md-6 form-group">
            <ReferentialPicker
              label={BOOKING_ENTITY}
              disabled={isDealCorrection}
              isValid={selectedBookingEntity.isValid}
              selected={selectedBookingEntity.selected}
              onSelect={handleOnBookingEntitySelect}
            />
          </div>
          <div className="col-md-6 form-group">
            <ReferentialPicker
              label={ORIGINATING_ENTITY}
              disabled={isDealCorrection}
              isValid={selectedOriginatingEntity.isValid}
              selected={selectedOriginatingEntity.selected}
              onSelect={handleOnOriginatingEntitySelect}
            />
          </div>
          <div className="col-md-6 form-group">
            <ReferentialPicker
              label={SUB_SUB_BUSINESS_LINE}
              disabled={isDealCorrection}
              isValid={selectedSsbl.isValid}
              selected={selectedSsbl.selected}
              onSelect={selected => {
                setIsSsblTouched(true);
                handleSsblSelect(selected);
              }}
            />
          </div>
          <div className="col-md-6 form-group">
            <ReferentialPicker
              label={PROFIT_CENTER}
              additionalFilter={profitCenterSearchTerms}
              disabled={isPcDropDownDisabled || isDealCorrection}
              isValid={selectedProfitCenter.isValid}
              selected={
                !isProfitCenterTouched && !selectedProfitCenter?.selected?.id
                  ? defaultProfitCenter
                  : selectedProfitCenter.selected
              }
              onSelect={handleProfitCenterSelect}
              defaultSelectedProfitCenter={items => defaultSelectedProfitCenter(items)}
            />
          </div>
          <div className="col-md-6 form-group">
            <ReferentialPicker
              label={PO}
              disabled={isDealCorrection}
              isValid={selectedPoClients.isValid}
              selected={selectedPoClients.selected}
              onSelect={selected => onSelect(selected, "PoClient")}
              includeKeys={true}
            />
          </div>
          {isDealCorrection && <div className="col-md-6 form-group" />}
          <BpClientsRenderer
            BpSharing={selectedBpSharing}
            isDealCorrection={isDealCorrection}
            isBpCorrection={isBpCorrection}
            onSelect={onSelect}
          />
          <div className="col-md-6 form-group">
            <ReferentialPicker
              label={AGENCY_NAME}
              disabled={isDealCorrection}
              isValid={selectedAgencyNames.isValid}
              selected={selectedAgencyNames.selected}
              onSelect={selected => onSelect(selected, "AgencyName")}
            />
          </div>
          <div className="col-md-6 form-group">
            <ReferentialPicker
              label={PRODUCT_LABEL}
              disabled={isDealCorrection}
              isValid={selectedProductLabels.isValid}
              selected={selectedProductLabels.selected}
              onSelect={selected => onSelect(selected, "ProductLabel")}
            />
          </div>
          {correctionType.correctionType === CorrectionTypeEnum.NBI && (
            <div className="col-md-6 form-group">
              <ReferentialPicker
                label={INDICATOR}
                disabled={isDealCorrection}
                isValid={selectedIndicator.isValid}
                selected={selectedIndicator.selected}
                onSelect={selected => onSelect(selected, "Indicator")}
                referentials={indicators}
              />
            </div>
          )}
          <div className="col-md-6 form-group">
            <FormField id={AMOUNT} label={isDealCorrection ? NEW_AMOUNT_IF_VALIDATED : AMOUNT}>
              <input
                className={`form-control ${amount.isValid ? "" : "is-invalid"}`}
                placeholder={isDealCorrection ? NEW_AMOUNT : AMOUNT}
                onChange={e => onAmountSelect(e.target.value)}
                required={true}
                value={amount.amount}
              />
              {!amount.isValid ? (
                <InvalidFeedback errorMessage={amountInputErrorMessages(amount.amount)} errorType="danger" />
              ) : (
                ""
              )}
            </FormField>
          </div>
        </div>
      )}

      {isBpCorrection && (
        <>
          <div className="row">
            <div className="col-md-8 form-group">
              <FormField id={DEAL_CODE} label={DEAL_CODE} className="form-group">
                <input className={`form-control`} placeholder={DEAL_CODE} value={dealCode} disabled={true} />
              </FormField>
            </div>
            <div className="col-md-4 form-group" />
            <div className="col-md-8 form-group">
              <ReferentialPicker
                label={PO}
                disabled={false}
                isValid={selectedPoClients.isValid}
                selected={selectedPoClients.selected}
                onSelect={selected => onSelect(selected, "PoClient")}
                includeKeys={true}
              />
            </div>
            <div className="col-md-6 form-group" />
            <BpClientsRenderer
              BpSharing={selectedBpSharing}
              isDealCorrection={isDealCorrection}
              isBpCorrection={isBpCorrection}
              onSelect={onSelect}
              onAddBp={onAddBp}
              removeBpClient={removeBpClient}
              onSharingSelect={onSharingSelect}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DealInformationsSection;
