import React from "react";
import { AccountSinglePickerDTO } from "./picker.typings";
export interface Props {
  isInError?: boolean;
  errorMessage?: string;
  selectedId: string;
  onChange?: (client: AccountSinglePickerDTO) => void;
}
const AccountSinglePicker: React.FC<Props> = ({ errorMessage, isInError, selectedId, onChange }: Props) => {
  const ref = React.useRef(null);
  const onSelectionChanged = (event: any) => {
    onChange(event.detail);
  };

  React.useEffect(() => {
    ref?.current?.addEventListener("ic-account-single-picker--changed", onSelectionChanged);
    return () => ref?.current?.removeEventListener("ic-account-single-picker--changed", onSelectionChanged);
  }, [onChange]);

  return (
    <ic-account-single-picker
      id="cpr-account-single-picker"
      ref={ref}
      placeholder="Search an account by name, bdr id or rct id"
      size="md"
      in-error={isInError}
      error-message={errorMessage}
      selected-id={selectedId}
    ></ic-account-single-picker>
  );
};

export default AccountSinglePicker;
