import React from "react";
import { get, map } from "lodash";
import { Highlight } from "@dcc-cli/ic-shared-components";
import { Fields } from "../components.typings";
import { IdAndLabel } from "../../../common/common.typings";

interface SelectDropDownItemProps<T extends IdAndLabel<number | string>> {
  onSelection: (selected: T) => void;
  selected?: T;
  items: T[];
  searchTerm?: string;
  displayField?: Fields<T>;
}

function SelectDropDownItem<T extends IdAndLabel<number | string>>({
  onSelection,
  selected,
  items,
  searchTerm,
  displayField = "name",
}: SelectDropDownItemProps<T>) {
  return (
    <>
      {map(
        items,
        item =>
          item.id !== selected?.id && (
            <button
              key={item.id}
              className="list-group-item list-group-item-action pointer"
              onMouseDown={e => e.preventDefault()}
              onClick={() => onSelection(item)}
            >
              <Highlight term={[searchTerm]} text={get(item, displayField)} color="info" />
            </button>
          )
      )}
    </>
  );
}

export default SelectDropDownItem;
