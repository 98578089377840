import React from "react";
import { CorrectionsStatusList } from "./CorrectionsStatusList";

export const CorrectionsStatusListContainer: React.FC = () => {
  return (
    <div className="container-fluid">
      <CorrectionsStatusList />
    </div>
  );
};
