import { ComponentSize } from "@dcc-cli/ic-shared-components/common/typings";
import { filter, get, isEmpty, noop } from "lodash";
import React from "react";
import { IdAndLabel } from "../../../common/common.typings";
import { useDropdownState } from "../../../hooks/useDropdownState";
import { doesContainSubString } from "../../../utils/strings/stringUtils";
import { Fields } from "../components.typings";
import { Spinner } from "../Spinner/Spinner";
import SelectDropDownItem from "./SelectDropDownItem";

interface Props<T extends IdAndLabel<number | string>> {
  onSelection?: (selected: T | null) => void;
  onChangeSearchTerm?: (searchTerm: string) => void;
  items: T[];
  selected?: T;
  className?: string;
  iconName?: string;
  size?: ComponentSize;
  placeHolder?: string;
  withOverflow?: boolean;
  disabled?: boolean;
  isValid?: boolean;
  invalidMessage?: string;
  selectDisplayField?: Fields<T>;
  elementsDisplayField?: Fields<T>;
}

function SelectDropDownSearch<T extends IdAndLabel<number | string>>({
  onSelection = noop,
  onChangeSearchTerm = noop,
  items,
  selected,
  className = "",
  iconName = "search",
  size = "md",
  placeHolder = "",
  disabled = false,
  selectDisplayField = "label",
  elementsDisplayField = "label",
  isValid = false,
  invalidMessage,
}: Props<T>) {
  const ref = React.useRef(null);
  const [isDropDownShownState, setToggleDropdown] = useDropdownState(ref, false);
  const [searchTerm, setSearchTerm] = React.useState<string>(selected?.label || "");
  const [isLoading, setLoading] = React.useState(false);
  const [placeHolderText, setPlaceHolderText] = React.useState(selected?.label !== "" ? selected?.label : placeHolder);
  React.useEffect(() => {
    setSearchTerm(selected?.label || "");
  }, [selected]);

  React.useEffect(() => {
    if (isEmpty(searchTerm)) {
      setLoading(false);
    }
  }, [searchTerm]);

  React.useEffect(() => {
    setLoading(false);
  }, [items]);

  const handleOnInputClick = () => {
    setSearchTerm("");
    setToggleDropdown(!isDropDownShownState);
  };

  const handleOnSelection = (item: T) => {
    setSearchTerm(item?.label);
    onSelection(item);
    setToggleDropdown(false);
    setPlaceHolderText(item.label);
  };

  const handleOnSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleDropdown(true);
    setSearchTerm(event.target.value);
    if (!isEmpty(event.target.value)) {
      onChangeSearchTerm(event.target.value);
    }
    setLoading(true);
  };

  const handleOnClear = () => {
    handleOnSelection({ id: null, label: "" } as T);
  };

  const value = isDropDownShownState ? searchTerm : get(selected, selectDisplayField, "");

  const filteredItems = filter(items, item => doesContainSubString(get(item, elementsDisplayField), value));

  const noResult = !isLoading && items?.length === 0 && !isEmpty(searchTerm);

  const dropDownHeight = { maxHeight: value !== "" || !isEmpty(items) ? "250px" : "0px" };
  return (
    <div
      ref={ref}
      className={`input-group input-group-${size} ${className}`}
      onClick={() => (disabled ? noop() : handleOnInputClick())}
    >
      {!disabled && iconName && (
        <div className="input-group-prepend input-group-merged">
          <i className="icon text-secondary">{iconName}</i>
        </div>
      )}
      <input
        type="text"
        className={`form-control form-control-${size} ${disabled ? "" : "ignore-read-only-style"} ${
          isValid ? "" : "is-invalid"
        }`}
        disabled={disabled}
        value={value}
        placeholder={placeHolder !== "" ? placeHolder : placeHolderText}
        onChange={handleOnSearchTermChange}
      />

      <div className="input-group-append input-group-merged">
        {selected?.label && !disabled && (
          <button className="btn btn-link p-0 pointer text-medium" onClick={handleOnClear}>
            Clear
          </button>
        )}
        {!disabled && (
          <button className="btn btn-link p-0">
            {isLoading ? (
              <Spinner />
            ) : (
              <i className={`icon icon-${size} pointer`}>
                {isDropDownShownState ? "arrow_drop_up" : "arrow_drop_down"}
              </i>
            )}
          </button>
        )}
      </div>

      <div className="invalid-feedback">{invalidMessage}</div>
      {isDropDownShownState && !disabled && (
        <div className="card card-dropdown shadow-lg" style={dropDownHeight}>
          <div className="card-body p-0 text-medium overflow-auto">
            {noResult ? (
              <div className="list-group-item list-group-item-action pointer"> No result</div>
            ) : (
              <SelectDropDownItem
                items={filteredItems}
                searchTerm={searchTerm}
                displayField={elementsDisplayField}
                selected={selected}
                onSelection={handleOnSelection}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectDropDownSearch;
