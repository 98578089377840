import React from "react";
import { DropdownButton, InputGroup } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { map } from "lodash";
import { DEAL_SEARCH_MODE } from "enums/DealEnum";
export interface Props {
  className: string;
  inputValue: string;
  handleSearchMode(searchMode: DEAL_SEARCH_MODE): void;
}

export const DealsMultipleCriteriaSearch: React.FC<Props> = ({ handleSearchMode }: Props) => {
  const [selectedValue, setSelectedValue] = React.useState<DEAL_SEARCH_MODE>(DEAL_SEARCH_MODE.dealCode);

  const handleSelect = (e: DEAL_SEARCH_MODE) => {
    setSelectedValue(DEAL_SEARCH_MODE[e]);
    handleSearchMode(DEAL_SEARCH_MODE[e]);
  };

  return (
    <>
    <div>
      <InputGroup>
        <DropdownButton
          variant="outline-secondary"
          title={selectedValue}
          id="input-group-dropdown-1"
          onSelect={handleSelect}
        >
          {map(DEAL_SEARCH_MODE, (item, index) => (
            <Dropdown.Item key={index} eventKey={index}>
              {item}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </InputGroup>
      </div>
    </>
  );
};
