import React from "react";
import { ROUTES } from "common/routes";
import { NavItemContainer } from "components/common/NavItemContainer/NavItemContainer";

export const HeaderMenu: React.FunctionComponent = () => (
  <ul data-testid={"header-menu"} className="navbar-nav mr-auto mt-2 mt-lg-0">
    <div className="right">
      <NavItemContainer to={ROUTES.DEALS}>
        <span>Deal search</span>
      </NavItemContainer>
    </div>
    <NavItemContainer to={ROUTES.CORRECTIONS}>
      <span>Corrections status</span>
    </NavItemContainer>
  </ul>
);
