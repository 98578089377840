import React from "react";

/***
 *  Return the state to be used for toggling a dropdown
 *  Will hide the dropdown on click outside and on escape key
 * @param ref dropdown reference to use when checking a click is outside the element or not
 * @param initialValue true/false the initial state value
 */
export const useDropdownState = (
  ref,
  initialValue = false
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isDropDownShown, toggleDropdown] = React.useState(initialValue);

  const onWindowsClick = React.useCallback(
    (event: MouseEvent) => {
      if (!ref || !ref.current) {
        return;
      }
      if (!ref.current.contains(event.target)) {
        toggleDropdown(false);
      }
    },
    [ref]
  );

  const onEscKey = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      toggleDropdown(false);
    }
  };

  React.useEffect(() => toggleDropdown(initialValue), [initialValue]);

  React.useEffect(() => {
    document.body.addEventListener("click", onWindowsClick);
    return () => document?.body?.removeEventListener("click", onWindowsClick);
  }, [onWindowsClick]);

  React.useEffect(() => {
    window.addEventListener("keyup", onEscKey);
    return () => window?.removeEventListener("keyup", onEscKey);
  }, []);

  return [isDropDownShown, toggleDropdown];
};
