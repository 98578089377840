import React from "react";
import classNames from "classnames";

export interface Props {
  className?: string;
}

export const Footer: React.FunctionComponent<Props> = ({ className }: Props) => (
  <footer data-testid={"footer"} className={classNames("mt-3 bg-light-1", className)}>
    <sgwt-mini-footer
      id="cpr-mini-footer"
      type="compact"
      design="light"
      container="container-fluid"
      mode="sg-markets"
      contact-us="{'mail': 'list.world-gbsu-cld-cli-cprofit-reporting@sgcib.com'}"
    />
  </footer>
);
